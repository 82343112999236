import Firebase from 'firebase/app';

import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from 'firebase/app';
const firebaseConfigLocal = {
  apiKey: 'AIzaSyCyzRUodRCRB5TOkI7EyvjGI-8V0d8XTUc',
  authDomain: 'crowdfunding-wl-dev.firebaseapp.com',
  databaseURL: 'https://crowdfunding-wl-dev.firebaseio.com',
  projectId: 'crowdfunding-wl-dev',
  storageBucket: 'crowdfunding-wl-dev.appspot.com',
  messagingSenderId: '536275405970',
  appId: '1:536275405970:web:6d6a4c11dbe3415631aa4b',
  measurementId: 'G-V3R6KNC8YJ',
};

const firebaseConfigDEV = {
  apiKey: 'AIzaSyCyzRUodRCRB5TOkI7EyvjGI-8V0d8XTUc',
  authDomain: 'crowdfunding-wl-dev.firebaseapp.com',
  databaseURL: 'https://crowdfunding-wl-dev.firebaseio.com',
  projectId: 'crowdfunding-wl-dev',
  storageBucket: 'crowdfunding-wl-dev.appspot.com',
  messagingSenderId: '536275405970',
  appId: '1:536275405970:web:6d6a4c11dbe3415631aa4b',
  measurementId: 'G-V3R6KNC8YJ',
};

const firebaseConfigQAS = {
  apiKey: 'AIzaSyAL6C44TlTLphm6UQkuVOwbexXFpVLolZE',
  authDomain: 'crowdfunding-wl-qas.firebaseapp.com',
  databaseURL: 'https://crowdfunding-wl-qas.firebaseio.com',
  projectId: 'crowdfunding-wl-qas',
  storageBucket: 'crowdfunding-wl-qas.appspot.com',
  messagingSenderId: '8533347744',
  appId: '1:8533347744:web:e001866c7bb9ae73f5a8c5',
  measurementId: 'G-2FSF4KMRDH',
};

const firebaseConfigDEMO = {
  apiKey: 'AIzaSyD-Z7fN-AV-gnmIuAFvj43cECDYepRnMHU',
  authDomain: 'cwl-demo.firebaseapp.com',
  databaseURL: 'https://cwl-demo.firebaseio.com',
  projectId: 'cwl-demo',
  storageBucket: 'cwl-demo.appspot.com',
  messagingSenderId: '207854671484',
  appId: '1:207854671484:web:8cecc40d3d67477d2c1bf9',
  measurementId: 'G-MFP2HG8JR6',
};

const firebaseConfigEQUITY = {
  apiKey: 'AIzaSyDkY74sxzx-1nLMNZIBghf71J1R6VTgRMg',
  authDomain: 'cwl-equity.firebaseapp.com',
  databaseURL: 'https://cwl-equity.firebaseio.com',
  projectId: 'cwl-equity',
  storageBucket: 'cwl-equity.appspot.com',
  messagingSenderId: '936427759471',
  appId: '1:936427759471:web:de7d7ae3708f0ea5bd6a9a',
  measurementId: 'G-F7P3PL778Z',
};

const firebaseConfigDebt = {
  apiKey: 'AIzaSyDWscGWLqT3Zj1fGAibIwsf6Tef7_1mSmU',
  authDomain: 'cwl-debit.firebaseapp.com',
  projectId: 'cwl-debit',
  storageBucket: 'cwl-debit.appspot.com',
  messagingSenderId: '366015429981',
  appId: '1:366015429981:web:7d46870ca5934290057cb4',
};

const firebaseConfigRecompensaOficial = {
  apiKey: 'AIzaSyBhiC1qyKfcbMGgJo-0ardqnL7Ms89VGlw',
  authDomain: 'cwl-recompensa-oficial.firebaseapp.com',
  projectId: 'cwl-recompensa-oficial',
  storageBucket: 'cwl-recompensa-oficial.appspot.com',
  messagingSenderId: '1021947962473',
  appId: '1:1021947962473:web:303676133c864207326ef8',
};
const firebaseConfigDoacaoOficial = {
  apiKey: 'AIzaSyD7LCZUwQVLyQjMHOzvpsM1wTtPy1KLUFo',
  authDomain: 'cwl-doacao-oficial.firebaseapp.com',
  projectId: 'cwl-doacao-oficial',
  storageBucket: 'cwl-doacao-oficial.appspot.com',
  messagingSenderId: '475121842580',
  appId: '1:475121842580:web:b8007c444effd0db4a3781',
  measurementId: 'G-V0SBHHZ045',
};

const firebaseConfigEquityOficial = {
  apiKey: 'AIzaSyDy0XdncPwInk2alrIepRP23iXHEXuXgws',
  authDomain: 'cwl-equity-oficial.firebaseapp.com',
  projectId: 'cwl-equity-oficial',
  storageBucket: 'cwl-equity-oficial.appspot.com',
  messagingSenderId: '253867918718',
  appId: '1:253867918718:web:0f9afa42b0b552e9181f2f',
  measurementId: 'G-45L8TP5CT4',
};

const firebaseConfigICoOwner = {
  apiKey: 'AIzaSyDxBC-WTf5yJsl8elq_TxxggDD80lFrv2k',
  authDomain: 'ecwl-icoowner.firebaseapp.com',
  projectId: 'ecwl-icoowner',
  storageBucket: 'ecwl-icoowner.appspot.com',
  messagingSenderId: '618596401311',
  appId: '1:618596401311:web:f4b8c4d87bf5c8e81722aa',
};

const firebaseConfigICoOwnerStage = {
  apiKey: 'AIzaSyCPkzqRuMi9cIsW9riVwSEZgQYNg8HC8Qk',
  authDomain: 'ecwl-icoowner-stage.firebaseapp.com',
  projectId: 'ecwl-icoowner-stage',
  storageBucket: 'ecwl-icoowner-stage.appspot.com',
  messagingSenderId: '515879709351',
  appId: '1:515879709351:web:56ec6b4ff2b3d2d8cd4982',
};

const firebaseConfigBacenDEV = {
  apiKey: "AIzaSyBFAal78o3DNpjrpnmHCIk5rhxOXFesMaA",
  authDomain: "ecwl-bacen-dev.firebaseapp.com",
  projectId: "ecwl-bacen-dev",
  storageBucket: "ecwl-bacen-dev.appspot.com",
  messagingSenderId: "1022060028558",
  appId: "1:1022060028558:web:fef9862fdb51ac03a25fdf"
};

const firebaseConfigAjxStage = {
  apiKey: "AIzaSyA0Oc92d25OFk13NG8j4Aabx0iVHdCFuu4",
  authDomain: "ecwl-ajx-stage.firebaseapp.com",
  projectId: "ecwl-ajx-stage",
  storageBucket: "ecwl-ajx-stage.appspot.com",
  messagingSenderId: "125153826482",
  appId: "1:125153826482:web:4f54d69718241a7a651bab",
  measurementId: "G-R917JJ5PSL"
};

const firebaseConfigAjxPRD = {
  apiKey: "AIzaSyBqlETt-dzgWoNAUUwOrYiYA0fhAkbo5BI",
  authDomain: "ecwl-ajx.firebaseapp.com",
  projectId: "ecwl-ajx",
  storageBucket: "ecwl-ajx.appspot.com",
  messagingSenderId: "524400075760",
  appId: "1:524400075760:web:a332cb53661fba7632b335",
  measurementId: "G-NE8592T7V4"
};


export const initializeFirebaseApp = (buildApp) => {
  let firebaseConfig = {};
  if (process.env.VUE_APP_ENVIRONMENT == 'local') {
    firebaseConfig = firebaseConfigLocal;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'development') {
    firebaseConfig = firebaseConfigDEV;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'qas') {
    firebaseConfig = firebaseConfigQAS;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'demo') {
    firebaseConfig = firebaseConfigDEMO;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'equity') {
    firebaseConfig = firebaseConfigEQUITY;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'prod') {
    firebaseConfig = firebaseConfigDEMO;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'debt') {
    firebaseConfig = firebaseConfigDebt;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'recompensa-oficial') {
    firebaseConfig = firebaseConfigRecompensaOficial;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'doacao-oficial') {
    firebaseConfig = firebaseConfigDoacaoOficial;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'equity-oficial') {
    firebaseConfig = firebaseConfigEquityOficial;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'icoowner') {
    firebaseConfig = firebaseConfigICoOwner;
  } else if (process.env.VUE_APP_ENVIRONMENT == 'icoowner-stage') {
    firebaseConfig = firebaseConfigICoOwnerStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'bacen-dev') {
    firebaseConfig = firebaseConfigBacenDEV;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'ajx-stage') {
    firebaseConfig = firebaseConfigAjxStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'ajx-prd') {
    firebaseConfig = firebaseConfigAjxPRD;
  }

  Firebase.initializeApp(firebaseConfig);
  Firebase.auth().onAuthStateChanged(() => {
    buildApp();
  });
};

export const database = () => Firebase.firestore();

export const collectionRef = (name) => {
  return firebase.firestore().collection(name);
};

export const currentUser = () => {
  return firebase.auth().currentUser;
};

export const currentUserId = () => {
  const user = firebase.auth().currentUser;
  return user ? user.uid : undefined;
};
